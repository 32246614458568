// import logo from "./logo.svg";
import "./App.css";
import { Button, Text } from "@mantine/core";
import ImageLink from "./Links";
import CurrentProjects from "./CurrentProjects";
import { ChevronRight } from "tabler-icons-react";
import resume from "./pdfs/resume.2022.pdf";

function App() {
  return (
    <div>
      <div className="Main">
        <div className="about">
          <h1 className="Greeting">Hello!</h1>
          <h3 className="Desc">
            I'm Curtis Munson, <br></br>A Software Engineer Specializing in
            CI/CD and Kubernetes.
          </h3>
          <a href={resume} target="_blank" rel="noreferrer noopener">
            <Button
              rightIcon={<ChevronRight />}
              style={{ color: "#a62858", background: "#def2ff" }}
              className="resume"
            >
              View Resume
            </Button>
          </a>
        </div>
        <div className="Projects">
          <h1 className="MyLinks">My Links</h1>
          <div className="link">
            <ImageLink
              src="https://cdn-icons-png.flaticon.com/512/25/25231.png"
              popContent="Github Page"
              link="https://github.com/BreadedToastWasTaken"
            />
            <ImageLink
              src="https://cdn-icons-png.flaticon.com/512/919/919853.png"
              popContent="Docker Hub Page"
              link="https://hub.docker.com/u/breadedtoasty"
            />
            <ImageLink
              src="https://www.freepnglogos.com/uploads/logo-gmail-png/logo-gmail-png-gmail-logo-icons-2.png"
              popContent="Email Me (curtis.munson02@gmail.com)"
              link="mailto:curtis.munson02@gmail.com"
            />
          </div>
        </div>
      </div>
      <div className="hr">
        <div className="content">
          <Text weight={700} className="CurrentProjectsH">
            Current Project(s)
          </Text>
          <CurrentProjects />
        </div>
      </div>
    </div>
  );
}

export default App;
