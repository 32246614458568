import { useState } from "react";
import { Image, Popover } from "@mantine/core";
import "./links.css";
export default function ImageLink(props: {
  src: string;
  popContent: string;
  link: string;
}) {
  const [opened, setOpened] = useState(false);
  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="top"
      placement="center"
      withArrow
      trapFocus={false}
      closeOnEscape={false}
      transition="pop"
      styles={{ body: { pointerEvents: "none" } }}
      target={
        <Image
          className="links"
          src={props.src}
          onClick={(e) => window.open(props.link, "_self")}
          radius="lg"
          onMouseEnter={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
        />
      }
    >
      {props.popContent}
    </Popover>
  );
}
