import { Card, Image, Text } from "@mantine/core";
import "./CurrentProjects.css";

export default function CurrentProjects() {
  return (
    <div className="CurrProj">
      <Card
        shadow="xl"
        radius="md"
        className="card"
        onClick={() => {
          window.open(
            "https://github.com/BreadedToastWasTaken/kubernetes-vagrant/",
            "_self"
          );
        }}
      >
        <Image
          className="image"
          radius={100}
          src="https://upload.wikimedia.org/wikipedia/labs/thumb/b/ba/Kubernetes-icon-color.svg/2110px-Kubernetes-icon-color.svg.png"
          fit="cover"
          height="10vh"
        />
        <Text className="desc">
          An application which uses vagrant and ansible to deploy a kubernetes
          cluster from scratch. It will support custom config files to determine
          cluster size a resource usage.
        </Text>
      </Card>
      {/* <Card shadow="xl" radius="md" className="card">
        <Image
          className="image"
          radius={100}
          src="https://i1.sndcdn.com/avatars-000453944376-7aeofp-t500x500.jpg"
          fit="cover"
          height="10vh"
        />
        <Text className="desc">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio
          quae nobis facere quam consectetur enim labore iure expedita velit sed
        </Text>
      </Card>
      <Card shadow="xl" radius="md" className="card">
        <Image
          className="image"
          radius={100}
          src="https://i1.sndcdn.com/avatars-000453944376-7aeofp-t500x500.jpg"
          fit="cover"
          height="10vh"
        />
        <Text className="desc">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio
          quae nobis facere quam consectetur enim labore iure expedita velit sed
        </Text>
      </Card> */}
    </div>
  );
}
